import { type GetListingQuery } from '@kijiji/generated/graphql-types'
import { useEffect, useRef } from 'react'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { createCookie } from '@/utils/cookies/createCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'

export const useVisitedCookie = (
  listingId: string,
  listing: GetListingQuery['listing'] | null,
  maxVpvListings: number
): void => {
  const hasRun = useRef(false)

  useEffect(() => {
    if (!listing || hasRun.current) return

    hasRun.current = true

    let updatedVipsVisited: string[] = []
    const cookie = getCookieByKey(document.cookie, CookieRegistry.VIPS_VIEWED)
    if (cookie) {
      try {
        updatedVipsVisited = decodeURIComponent(cookie).split(',')
      } catch (e) {
        // If the cookie is malformed, we will just ignore it
      }
    }

    if (updatedVipsVisited.includes(listingId)) {
      // If the listing is already in the cookie we don't need to update it
      return
    }

    updatedVipsVisited.push(listingId)
    if (updatedVipsVisited.length > maxVpvListings) {
      updatedVipsVisited.splice(0, updatedVipsVisited.length - maxVpvListings)
    }

    createCookie(CookieRegistry.VIPS_VIEWED, encodeURIComponent(updatedVipsVisited.join(',')), {
      expiryInDays: 7,
      secure: process.env.NODE_ENV !== 'development',
    })
  }, [hasRun, listing, listingId, maxVpvListings])
}
